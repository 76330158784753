import { Inject, Injectable } from '@angular/core';
import { ProcedureEnum } from '@core/procedure-helpers/models/procedure.enum';
import { combineQueries } from '@datorama/akita';
import { FeatureToggleSettings } from '@shared/models/enums/enums';
import { FeatureToggle } from '@shared/models/feature-toggle';
import { ProcedureMap } from '@shared/models/procedure-map';
import { RxModel } from '@shared/models/rx-models/interfaces/rx-model';
import { AddRxProcedureMapping } from '@shared/models/rx-rules-json-interface';
import { ADD_RX_RULES } from '@shared/rules/rules-tokens';
import { ShellQuery } from '@shared/store/shell/shell-query';
import { combineLatest, map, Observable } from 'rxjs';
import { VersionsService } from '@shared/services/versions.service';
import { LimitedFeatures } from '@shared/models/limited-features';
import { HostPlatformService } from '@shared/services/host-platform.service';

@Injectable({
	providedIn: 'root'
})
export class AddRxService {
	isRestorativeAddRxAvailable$ = combineLatest([this.shellQuery.featureToggles$, this.shellQuery.enableAllCaseTypesForAddRx$]).pipe(
		map(([featureToggles, enableAllCaseTypesForAddRx]) => this.isRestorativeAddRxAvailable(featureToggles, enableAllCaseTypesForAddRx))
	);
	clonedRxProcedureRule$ = combineQueries([
		this.shellQuery.isCloningMode$,
		this.shellQuery.clonedRx$,
		this.shellQuery.rx$,
		this.isRestorativeAddRxAvailable$
	]).pipe(
		map(([isCloningMode, clonedRx, rx, isRestoAddRxAvailable]) => {
			const procedure = clonedRx?.Order?.ProcedureId ?? rx?.Order?.ProcedureId;

			return isCloningMode && isRestoAddRxAvailable
				? this.addRxRules.find(rule => rule.OriginalProcedure === (procedure as ProcedureEnum))
				: null;
		})
	);

	isClonedRestorativeProcedure$ = combineLatest([this.clonedRxProcedureRule$, this.shellQuery.enableAllCaseTypesForAddRx$]).pipe(
		map(([rule, enableAllCaseTypesForAddRx]) => {
			// if the AvailableProcedures is one procedure then the duplicated Rx is a Fixed Restorative case
			return !enableAllCaseTypesForAddRx && rule?.AvailableProcedures?.length === 1;
		})
	);

	constructor(
		private shellQuery: ShellQuery,
		private versionService: VersionsService,
		private hostPlatformService: HostPlatformService,
		@Inject(ADD_RX_RULES) private addRxRules: AddRxProcedureMapping[]
	) {}

	get isNiriReadOnly$(): Observable<boolean> {
		return combineLatest([this.shellQuery.clonedRx$, this.isRestorativeAddRxAvailable$]).pipe(
			map(([clonedRx, isRestoAddRxAvailable]) => {
				return isRestoAddRxAvailable && !!clonedRx?.NIRIEnabled;
			})
		);
	}

	get isPreTreatmentReadOnly$(): Observable<boolean> {
		return combineLatest([this.shellQuery.clonedRx$, this.isRestorativeAddRxAvailable$]).pipe(
			map(([clonedRx, isRestoAddRxAvailable]) => {
				return (
					isRestoAddRxAvailable &&
					!!clonedRx?.PrePrepScan &&
					(clonedRx.Order?.ProcedureId as ProcedureEnum) === ProcedureEnum.FixedRestorative
				);
			})
		);
	}

	get isEmergencyProfileReadOnly$(): Observable<boolean> {
		return combineLatest([this.shellQuery.clonedRx$, this.isRestorativeAddRxAvailable$]).pipe(
			map(([clonedRx, isRestoAddRxAvailable]) => {
				return (
					isRestoAddRxAvailable &&
					!!clonedRx?.EmergenceProfile &&
					(clonedRx.Order?.ProcedureId as ProcedureEnum) === ProcedureEnum.FixedRestorative
				);
			})
		);
	}

	hasAddRxFt(featureToggles: FeatureToggle[]): boolean {
		return featureToggles?.some(x => (x.id as FeatureToggleSettings) === FeatureToggleSettings.AddRxNg && x.isActive);
	}

	isAllowToCloneOrder(procedureId: ProcedureEnum | null, availableProcedureMaps: ProcedureMap[] | null): boolean {
		return (
			this.addRxRules.some(rule => rule.OriginalProcedure === procedureId) &&
			!!availableProcedureMaps &&
			availableProcedureMaps.some(availableProcedureMap => (availableProcedureMap.ProcedureId as ProcedureEnum) === procedureId)
		);
	}

	isRestorativeAddRxAvailable(featureToggles: FeatureToggle[], enableAllCaseTypesForAddRx: boolean): boolean {
		return (
			!enableAllCaseTypesForAddRx &&
			this.hostPlatformService.isScanner &&
			this.hasAddRxFt(featureToggles) &&
			this.versionService.isFeatureAvailableForScanner(
				LimitedFeatures.RestorativeAddRx,
				this.shellQuery.clientVersion,
				this.shellQuery.packageVersion
			)
		);
	}

	cloneRx(clonedRx: RxModel): RxModel {
		return {
			...clonedRx,
			ID: '',
			ClonedFromRxID: clonedRx.ID,
			Order: {
				...clonedRx.Order,
				ID: null,
				Code: null,
				State: null,
				Status: null,
				ScanDate: null
			},
			IsSleeveConfirmed: null,
			Notes: null,
			AligntechNotes: null
		};
	}
}
